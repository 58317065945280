.box-sizing {
    box-sizing: border-box;
}

.item-image {
    aspect-ratio: 1 / 1;
}

.progress-bar {
    box-sizing: border-box;
}

.sale-button {
    border-width: 0;
}

.border {
    /* border: 0.1px solid rgba(0, 0, 0, 0.01); */
}