.special {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding-bottom: env(safe-area-inset-bottom);
}

.special-gray {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f5f5f5;
  padding-bottom: env(safe-area-inset-bottom);
}
