.button {
    text-align: center;
    box-sizing: border-box;
    width: 200px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 55px;
    text-align:center;
    border: none;
    background-size: 300% 100%;
    flex: 1;
    border-radius: 12px;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.button:hover {
    background-position: 100% 0;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.button:focus {
    outline: none;
}

.color-4 {
    background-image: linear-gradient(to right, #29323c, #485563, #eb3941, #ec8c69);
    box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);
}